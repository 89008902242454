/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Box, Grid, Heading, Text, Button } from 'theme-ui'
import { Link } from 'gatsby'
import React from 'react'

import { CallToBook, Gander, StayTheNight } from 'components'

import { AttractionMap, SEO, LiveEvents } from 'components'

import LogoStacked from '../../static/images/svg/logo-stacked.svg'

function HomeHero() {
  return (
    <Grid
      variant="boxes.container"
      pt={['xl', null, 'xxxl']}
      pb={['xl', null, 'xxxxl']}
      sx={{ alignItems: [null, null, 'center'] }}
      columns={[null, null, null, '2fr 3fr']}
      gap={['l', null, 'xxl']}
    >
      <Box sx={{ order: [2, null, null, 1] }}>
        <Box
          sx={{
            marginBottom: 'l',
            maxWidth: [200, null, 240, 300],
            marginX: 'auto',
            '& svg': {
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <LogoStacked sx={{ display: 'block', marginX: 'auto' }} />
        </Box>

        <Box
          sx={{
            borderTop: 'solid 1px',
            borderBottom: 'solid 1px',
            borderColor: 'black.10',
            paddingY: 's',
            marginBottom: 'm',
          }}
        >
          <Text sx={{ textAlign: 'center' }}>Open daily • Midday — 11pm</Text>
        </Box>
        <Box
          sx={{
            display: [null, null, 'flex'],
            marginBottom: 'l',
            justifyContent: 'center',
          }}
        >
          <Button as={Link} to="/accommodation" mr="m" mb={['s', null, 0]}>
            View our rooms
          </Button>
          <Button as={Link} variant="secondary" to="/food-and-drink">
            View our menus
          </Button>
        </Box>

        <CallToBook sx={{ justifyContent: 'center' }} />
      </Box>

      <Box sx={{ order: [1, null, null, 2] }}>
        <Box
          poster="/images/video-cover.jpg"
          variant="boxes.media"
          as="video"
          src="/hero.mp4"
          autoPlay
          loop
          muted
          playsInline
          sx={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',

            boxShadow: '0px 5px 20px rgba(0,0,0,0.18)',
            borderRadius: 4,
            maxWidth: '100%',
          }}
        />
      </Box>
    </Grid>
  )
}

function Index() {
  return (
    <>
      <SEO />
      <Box>
        <Box
          sx={{
            display: 'block',
            bg: 'orange.dark',
            color: 'white.100',
            py: 's',
            px: 'm',
            textAlign: 'center',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
          as={Link}
          to="/staying-safe"
        >
          National lockdown in place. Inn reopens on 17th May.
        </Box>

        <Box px={['m', null, 'l']}>
          <HomeHero />
        </Box>

        <AttractionMap showInfo />

        <LiveEvents />

        <StayTheNight />

        <Gander />
      </Box>
    </>
  )
}

export default Index
